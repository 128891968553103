var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c("v-app", [
        _c(
          "div",
          { staticClass: "inner-container" },
          [
            _c("div", { staticClass: "top-page-header" }, [
              _c("div", { staticClass: "head-title" }, [
                _c("h1", [_vm._v("App Settings")])
              ])
            ]),
            _c(
              "v-container",
              { staticClass: "v-main-content", attrs: { fluid: "" } },
              [
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "pa-4 rounded-lg",
                      attrs: { elevation: "3" }
                    },
                    [
                      _c(
                        "div",
                        { attrs: { id: "app" } },
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "pa-0" },
                            [
                              _vm._v(" App Settings "),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "", "max-width": "300px" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "ml-1",
                                                  attrs: {
                                                    depressed: "",
                                                    "min-width": "14",
                                                    "max-width": "14",
                                                    text: ""
                                                  }
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "secondary--text"
                                                },
                                                [
                                                  _vm._v(
                                                    "mdi-information-outline"
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "You can set the default values which are used while creating automted campaign. Values can be set different for every country"
                                    )
                                  ])
                                ]
                              ),
                              _c("v-spacer"),
                              _c(
                                "div",
                                { staticClass: "v-search" },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      "append-icon": "mdi-magnify",
                                      label: "Search",
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.search,
                                      callback: function($$v) {
                                        _vm.search = $$v
                                      },
                                      expression: "search"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-data-table", {
                            staticClass: "elevation-0 pa-0 mt-4",
                            attrs: {
                              loading: _vm.$apollo.queries.appsReports.loading,
                              "items-per-page": 5,
                              headers: _vm.appListHeaders,
                              items:
                                (_vm.appsReports && _vm.appsReports.row) || [],
                              search: _vm.search
                            },
                            scopedSlots: _vm._u(
                              [
                                _vm._l(Object.keys(_vm.$scopedSlots), function(
                                  slot
                                ) {
                                  return {
                                    key: slot,
                                    fn: function(scope) {
                                      return [_vm._t(slot, null, null, scope)]
                                    }
                                  }
                                }),
                                {
                                  key: "item.action",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "no-m-no-p",
                                              attrs: { cols: "6" }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    small: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.editData(item)
                                                    }
                                                  }
                                                },
                                                [_vm._v("mdi-pencil")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ]
                  )
                ]
              ],
              2
            ),
            _c(
              "v-dialog",
              {
                attrs: { "max-width": "900px" },
                model: {
                  value: _vm.editDataDialog,
                  callback: function($$v) {
                    _vm.editDataDialog = $$v
                  },
                  expression: "editDataDialog"
                }
              },
              [
                _c(
                  "v-card",
                  { staticClass: "pa-5", attrs: { outlined: "" } },
                  [
                    _c(
                      "v-card-title",
                      { staticClass: "d-flex justify-space-between pa-0 mb-0" },
                      [
                        _c("h5", [_vm._v("Set Values")]),
                        _c(
                          "div",
                          { staticClass: "v-search" },
                          [
                            _c("v-text-field", {
                              attrs: {
                                "append-icon": "mdi-magnify",
                                label: "Search",
                                "hide-details": ""
                              },
                              model: {
                                value: _vm.search,
                                callback: function($$v) {
                                  _vm.search = $$v
                                },
                                expression: "search"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "v-card-text",
                      [
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "rounded-0 border-x-0",
                              attrs: { outlined: "" }
                            },
                            [
                              _c("v-data-table", {
                                staticClass: "elevation-0 pa-0 mt-4",
                                attrs: {
                                  loading:
                                    _vm.$apollo.queries.appsReports.loading,
                                  "items-per-page": 5,
                                  headers: _vm.countryListHeaders,
                                  items: _vm.appCountries || [],
                                  search: _vm.search
                                },
                                scopedSlots: _vm._u(
                                  [
                                    _vm._l(
                                      Object.keys(_vm.$scopedSlots),
                                      function(slot) {
                                        return {
                                          key: slot,
                                          fn: function(scope) {
                                            return [
                                              _vm._t(slot, null, null, scope)
                                            ]
                                          }
                                        }
                                      }
                                    ),
                                    {
                                      key: "item.totalBudget",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "no-m-no-p",
                                                  attrs: { cols: "6" }
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      "hide-details": "",
                                                      disabled: _vm.textEditable
                                                    },
                                                    model: {
                                                      value: item.totalBudget,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "totalBudget",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.totalBudget"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.dailyCap",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "no-m-no-p",
                                                  attrs: { cols: "6" }
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      "hide-details": "",
                                                      disabled: _vm.textEditable
                                                    },
                                                    model: {
                                                      value: item.dailyCap,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "dailyCap",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.dailyCap"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.cpt",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "no-m-no-p",
                                                  attrs: { cols: "6" }
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      "hide-details": "",
                                                      disabled: _vm.textEditable
                                                    },
                                                    model: {
                                                      value: item.cpt,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "cpt",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.cpt"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "item.action",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "no-m-no-p",
                                                  attrs: { cols: "6" }
                                                },
                                                [
                                                  _vm.textEditable
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                            small: ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.textEditable = false
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("mdi-pencil")]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "div",
                                                    { staticClass: "d-flex" },
                                                    [
                                                      !_vm.textEditable
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "rounded-md success--text mx-2",
                                                              attrs: {
                                                                width: "10",
                                                                height: "20",
                                                                depressed: "",
                                                                outlined: "",
                                                                disabled:
                                                                  _vm.saving,
                                                                loading:
                                                                  _vm.saving
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.changeValues(
                                                                    item
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-check"
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      !_vm.textEditable
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "rounded-md error--text",
                                                              attrs: {
                                                                width: "10",
                                                                height: "20",
                                                                depressed: "",
                                                                outlined: "",
                                                                disabled:
                                                                  _vm.saving
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.textEditable = true
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "mdi-close"
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c(
                                "div",
                                { staticClass: "bottom right" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        width: "10",
                                        height: "20",
                                        depressed: "",
                                        disabled: _vm.saving
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.close()
                                        }
                                      }
                                    },
                                    [_vm._v(" Close ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      ],
                      2
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }